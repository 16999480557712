import { useCallback } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToAnchorOptions {
  smooth?: boolean;
}

interface ScrollToAnchorReturn {
  scrollToAnchor: () => void;
}

export const useScrollToAnchor = ({
  smooth = false,
}: ScrollToAnchorOptions = {}): ScrollToAnchorReturn => {
  const location = useLocation();

  const scrollToAnchor = useCallback(() => {
    const hash = location.hash.slice(1);
    const target = document.getElementById(hash);

    if (target) {
      setTimeout(() => {
        target.scrollIntoView({
          behavior: smooth ? "smooth" : "auto",
          block: "start",
        });
      }, 100);
    }
  }, [location, smooth]);

  return { scrollToAnchor };
};
