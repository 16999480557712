import React from 'react';
import { Link } from '@remix-run/react';

type Props = {
  color: string;
  text: string;
  linkUrl?: string;
  icon: string;
  isTargetBlank?: boolean;
  id?: string;
  handler?: React.MouseEventHandler<HTMLButtonElement>;
};

const Contact1Button: React.FC<Props> = (props) => {
  if (props.linkUrl && props.isTargetBlank) {
    return (
      <Link
        id={props.id}
        to={props.linkUrl}
        target="_blank"
        rel="noreferrer"
        className={`group ${props.color} inline-block w-[280px] p-2.5  rounded-full border-2 bg-white shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] [&.primary]:border-primary [&.primary]:text-primary hover:[&.primary]:bg-primary-light [&.green]:border-green [&.green]:text-green hover:[&.green]:bg-green-light`}
      >
        <span className="flex items-center justify-center">
          <span className="text-base font-bold leading-[1.8] md:text-lg md:leading-[1.8]">
            {props.text}
          </span>
          <span className="ml-2.5">
            <svg
              className={`block w-4 h-4 group-[.primary]:fill-primary group-[.green]:fill-green`}
            >
              <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
            </svg>
          </span>
        </span>
      </Link>
    );
  } else if (props.linkUrl) {
    return (
      <Link
        to={props.linkUrl}
        className={`group ${props.color} inline-block w-[280px] p-2.5  rounded-full border-2 bg-white shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] [&.primary]:border-primary [&.primary]:text-primary hover:[&.primary]:bg-primary-light [&.green]:border-green [&.green]:text-green hover:[&.green]:bg-green-light`}
      >
        <span className="flex items-center justify-center">
          <span className="text-base font-bold leading-[1.8] md:text-lg md:leading-[1.8]">
            {props.text}
          </span>
          <span className="ml-2.5">
            <svg
              className={`block w-4 h-4 group-[.primary]:fill-primary group-[.green]:fill-green`}
            >
              <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
            </svg>
          </span>
        </span>
      </Link>
    );
  } else {
    return (
      <button
        onClick={props.handler}
        className={`group ${props.color} inline-block w-[280px] p-2.5  rounded-full border-2 bg-white shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] [&.primary]:border-primary [&.primary]:text-primary hover:[&.primary]:bg-primary-light [&.green]:border-green [&.green]:text-green hover:[&.green]:bg-green-light`}
      >
        <span className="flex items-center justify-center">
          <span className="text-base font-bold leading-[1.8] md:text-lg md:leading-[1.8]">
            {props.text}
          </span>
          <span className="ml-2.5">
            <svg
              className={`block w-4 h-4 group-[.primary]:fill-primary group-[.green]:fill-green`}
            >
              <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
            </svg>
          </span>
        </span>
      </button>
    );
  }
};

export default Contact1Button;
