import React from 'react';

type Props = {
  text: string;
  icon?: string;
  color?: string;
  disabled?: boolean;
  id?: string;
  handler?: React.MouseEventHandler<HTMLButtonElement>;
};

const MediumButton: React.FC<Props> = (props) => {
  const disabled = props.disabled || false;

  if (props.icon === undefined) {
    return (
      <button
        id={props.id}
        onClick={props.handler}
        className={`inline-block py-3 rounded-full border-2 border-white bg-primary text-white text-base leading-normal text-center font-bold shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] hover:bg-opacity-70 md:px-12 md:text-lg ${props.color} [&.gray]:bg-gray-light [&.gray]:text-gray hover:[&.gray]:bg-gray-hover [&.white]:border-primary [&.white]:bg-white [&.white]:text-primary hover:[&.white]:bg-primary-light [&.primary-gradient]:bg-primary-gradient hover:[&.primary-gradient]:bg-primary-gradient-hover`}
        {...(disabled && { disabled })}
      >
        <span>{props.text}</span>
      </button>
    );
  } else {
    return (
      <button
        onClick={props.handler}
        className={`inline-block py-3 rounded-full border-2 border-white bg-primary text-white text-base leading-normal text-center font-bold shadow-[4px_4px_0_0_rgba(51,51,51,0.25)] hover:bg-opacity-70 md:px-12 md:text-lg ${props.color} [&.gray]:bg-gray-light [&.gray]:text-gray hover:[&.gray]:bg-gray-hover [&.white]:border-primary [&.white]:bg-white [&.white]:text-primary hover:[&.white]:bg-primary-light [&.primary-gradient]:bg-primary-gradient hover:[&.primary-gradient]:bg-primary-gradient-hover`}
        {...(disabled && { disabled })}
      >
        <span className="flex items-center justify-center">
          <span>{props.text}</span>
          <span className="flex-shrink-0 inline-block ml-2">
            <svg className="block w-5 h-5 fill-white">
              <use xlinkHref={`/symbol-defs.svg#icon-${props.icon}`}></use>
            </svg>
          </span>
        </span>
      </button>
    );
  }
};

export default MediumButton;
