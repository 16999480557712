import { createContext, ReactNode, useContext } from "react";

type EnvironmentContextType = {
  staticImageBucketEndpoint: string;
};

const EnvironmentContext = createContext<EnvironmentContextType | undefined>(
  undefined
);
interface EnvironmentProviderProps {
  children: ReactNode;
  staticImageBucketEndpoint: string;
}

export const EnvironmentProvider = ({
  children,
  staticImageBucketEndpoint,
}: EnvironmentProviderProps) => {
  return (
    <EnvironmentContext.Provider value={{ staticImageBucketEndpoint }}>
      {children}
    </EnvironmentContext.Provider>
  );
};

export const useEnvironment = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error(
      "useEnvironment must be used within an EnvironmentProvider"
    );
  }
  return context;
};
